define('client/components/no-ui-slider', ['exports', 'nouislider'], function (exports, _nouislider) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  // - no click glow on button
  // - no black change to button on click
  // - closer popover on focus change
  var NoUiSliderComponent = Ember.Component.extend({
    classNames: ["slider"],

    // Properties to pass the noUISlider, most likely passed in
    range: null,
    step: 1,
    snap: true,
    connect: "lower",

    title: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('range', this.get('range') || {
        'min': 0,
        '25%': 1,
        '50%': 2,
        '75%': 3,
        'max': 4
      });
    },


    didInsertElement: function didInsertElement() {
      var _this = this;

      _nouislider.default.create(this.$(".slider-input")[0], {
        start: this.get("content") === null ? 0.5 : +this.get("content"),
        connect: this.get("connect"),
        snap: this.get('snap'),
        step: this.get('step'),
        range: this.get('range')
      }).on("set", function (values, handle) {
        var val = +values[handle],
            oldVal = _this.content === null ? null : +_this.content;
        if (val !== oldVal) {
          _this.set("content", val);
          _this.change ? _this.change(val) : null;
        }
      });
    },

    didReceiveAttrs: function didReceiveAttrs() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var content = this.get("content");
        if (content != undefined) {
          this.$(".slider-input")[0].noUiSlider.set(content);
        }
      });
    }
  });

  exports.default = NoUiSliderComponent;
});